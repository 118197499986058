import PropTypes from "prop-types"
import React from "react"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

var showdown = require("showdown")

class ChartSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var chartContent = this.props.chartContent

    return (
      <div className="body-section ">
        <div className="columns" style={{ paddingBottom: "32px" }}>
          <div className="column is-5"></div>
          <div className="column">
            <MarkdownViewer markdown={chartContent.chartSectionText} />
          </div>
          <div className="column is-5"></div>
        </div>
        <div className="columns">
          <div className="column is-5"></div>
          <div className="column">
            <Chart content={chartContent.chartSectionChart} />
          </div>
          <div className="column is-5"></div>
        </div>
      </div>
    )
  }
}

const Chart = props => {
  return (
    <div className="di-chart">
      <h5 className="has-text-centered di-chart__header">
        {props.content.title}
      </h5>
      <table>
        <tr className="di-chart__row-header">
          {props.content.headers.map((header, i) => {
            return <th key={i}>{header}</th>
          })}
        </tr>

        {props.content.rows.map((row, i) => {
          return (
            <tr className="di-chart__row-data" key={i}>
              {row.map((value, j) => {
                return <td key={j}>{value}</td>
              })}
            </tr>
          )
        })}
      </table>
    </div>
  )
}

ChartSection.propTypes = {
  chartContent: PropTypes.object,
  chartSectionText: PropTypes.string
}

export default ChartSection
