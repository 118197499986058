import { Link } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../Button/ButtonGroupMap"
import { wrapSup } from "../../utils/utils"

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { hasSidebar, sidebarLinks, sidebarHeading } = this.props
    let sectionClasses = classNames(
      "body-sections section joshua-tree-content",
      {
        "with-sidebar": hasSidebar,
        "color-back": this.props.colorBack
      }
    )

    return (
      <section
        className={sectionClasses}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          <div className="column">
            <MarkdownViewer markdown={this.props.textSection} />

            {this.props.buttons && (
              <ButtonGroupMap isCentered buttons={this.props.buttons} />
            )}
          </div>

          {hasSidebar && (
            <>
              <div className="column is-1"></div>
              <div className="column is-narrow">
                <div className="procedure-sidebar">
                  {sidebarHeading && <h5>{wrapSup(sidebarHeading)}</h5>}
                  <ul className="procedure-sidebar__links">
                    {sidebarLinks.map(l => (
                      <li key={l.link.url}>
                        <Link
                          to={l.link.url}
                          title={`Learn more about ${l.link.name.toLowerCase()}`}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: wrapSup(l.link.name)
                            }}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}

          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
      </section>
    )
  }
}

MarkdownSection.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default MarkdownSection
